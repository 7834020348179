import React from 'react';
import Layout from '../components/layout';
import WordRotator from '../components/word-rotator';
import Testimonial from '../components/testimonial';
import CloudSolutions from '../components/cloud-solutions';
import '../styles/home.scss'
import {StaticImage} from "gatsby-plugin-image";
import {Link} from "gatsby";


class IndexPage extends React.Component {
  render() {

    return (
      <div>
        <Layout>
          <section className="image-section">
            <div className="mobile-image">
              <StaticImage src='../images/mobile-blue.png' alt='image'/>
            </div>
          </section>

          <section className='section-products'>
            <h1>Get what your business needs</h1>
            <div className='product-container'>
              <div className='card'>
                <div className='product-content'>
                  <p className='product-name'>EV Charging</p>
                  <StaticImage className='product-image' alt="oos" src="../images/charging-battery.png" loading='lazy'/>
                  <p className='product-text'>
                    Discover the basic ingredients you’ll need to get a successful EV charger business up and running.
                    DD Zone Charge is the best platform to connect and control EV chargers at scale.
                  </p>
                </div>
                <button className='more-button'>
                  <a href='https://charge.ddzone.xyz' className='link-text' target='_blank'>
                    Learn More
                  </a>
                </button>
              </div>
              <div className='card'>
                <div className='product-content'>
                  <p className='product-name'>Unified Commerce</p>
                  <StaticImage className='product-image' alt="oos" src="../images/pos-terminal.png" loading='lazy'/>
                  <p className='product-text'>
                    One unified solution for online and in-person retail stores to handle the busiest environments, from fine dining to quick service.
                  </p>
                </div>
                <button className='more-button'>
                  <a href='https://pos.ddzone.xyz' className='link-text' target='_blank'>
                    Learn More
                  </a>
                </button>
              </div>
              <div className='card'>
                <div className='product-content'>
                  <p className='product-name'>Smart Parking</p>
                  <StaticImage className='product-image' alt="oos" src="../images/parking-area.png" loading='lazy'/>
                  <p className='product-text'>
                    Providing best-in-class solutions to simplify operations of valet stack holders.
                    Stack holders could be Valet providers, Restaurants, Visitors.
                  </p>
                </div>
                <button className='more-button'>
                  <a href='https://valet.ddzone.xyz' className='link-text' target='_blank'>
                    Learn More
                  </a>
                </button>
              </div>
              <div className='card'>
                <div className='product-content'>
                  <p className='product-name'>Utility Apps</p>
                  <StaticImage className='product-image' alt="oos" src="../images/utility.png" loading='lazy'/>
                  <p className='product-text'>
                    Enjoy our out of the box free utility apps, which helps you to manage your health statics and allows to create digital business card.
                  </p>
                </div>
                <button className='more-button'>
                  <Link to='/utility' className='link-text'>
                    Learn More
                  </Link>
                </button>
              </div>
              <div className='card'>
                <div className='product-content'>
                  <p className='product-name'>Self-Service Tools</p>
                  <StaticImage className='product-image' alt="oos" src="../images/utility.png" loading='lazy'/>
                  <p className='product-text'>
                    Enjoy our out of the box free utility apps, which helps you to manage your health statics and allows to create digital business card.
                  </p>
                </div>
                <button className='more-button'>
                  <Link to='/self-service-tools' className='link-text'>
                    Learn More
                  </Link>
                </button>
              </div>
              <div className='card'>
                <div className='product-content'>
                  <p className='product-name'>Luxury Ride</p>
                  <StaticImage className='product-image' alt="oos" src="../images/utility.png" loading='lazy'/>
                  <p className='product-text'>
                    Enjoy our out of the box free utility apps, which helps you to manage your health statics and allows to create digital business card.
                  </p>
                </div>
                <button className='more-button'>
                  <Link to='/ride' className='link-text'>
                    Learn More
                  </Link>
                </button>
              </div>
            </div>
          </section>
          <hr/>
          <section className='clients'>
            <div className='clients-title'>
              <h1>Our Clients</h1>
            </div>
            <div className='clients-container'>
              <StaticImage className='logo' src='../images/clients/the-ritz-carlton.svg' alt='image'/>
              <StaticImage className='logo' src='../images/clients/radisson.png' alt='image'/>
              <StaticImage className='logo' src='../images/clients/holiday_inn.png' alt='image'/>
              <StaticImage className='logo' src='../images/clients/doubletree.png' alt='image'/>
              <StaticImage className='logo' src='../images/clients/embassy.png' alt='image'/>
              <StaticImage className='logo' src='../images/clients/archer.png' alt='image'/>
              <StaticImage className='logo' src='../images/clients/cambria.png' alt='image'/>
              <StaticImage className='logo' src='../images/clients/fantasy-world.png' alt='image'/>
              <StaticImage className='logo' src='../images/clients/crowne-plaza.png' alt='image'/>
              <StaticImage className='logo' src='../images/clients/wyndham.svg' alt='image'/>
            </div>
          </section>
        </Layout>
      </div>
    );
  }
}

export default IndexPage;
